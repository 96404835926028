import { gql } from "@apollo/client";

export const GET_CVES = gql`
  query GetCVEs(
    $scopeMrn: String!
    $first: Int
    $after: String
    $orderBy: FindingsOrder
    $filter: FindingsFilter
  ) {
    findings(
      scopeMrn: $scopeMrn
      first: $first
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      ... on FindingsConnection {
        totalCount
        filteredTotalCount
        edges {
          cursor
          node {
            ... on CveFinding {
              id
              mrn
              title
              asset {
                id
              }
              rating
              riskScore
              lastUpdated
              publishedAt
              tags {
                key
                value
              }
              riskFactors {
                mrn
                indicator
                title
                affected
                total
              }
              lastUpdated
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;
